import "./App.css";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Disclaimer from "./js/disclaimer";
import { useNavigate, useLocation } from "react-router-dom";
import trackVisitor from "./utilities/tracker";
import "moment-timezone";
import DaySattaResult from "./js/daySattaResult";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";
const momenttz = require("moment-timezone");

function App() {
  const location = useLocation();
  const isDisc = location.pathname.includes("/disclaimer");
  var currentDate = moment().tz("Asia/Kolkata").format("YYYY-MM-DD");

  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm:ss")); // Updated to show seconds
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]); // State to store processed data for display
  var prevDate = moment()
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");

  useEffect(() => {
    trackVisitor();
  }, []);

  // Set interval to update the time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("HH:mm:ss"));
    }, 1000); // Updates every second

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  // page scroll
  function pageScroll() {
    const tableSection = document.getElementById("monthTable");
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    if (data?.length > 0) {
      const currentMoment = moment(currentTime, "HH:mm");
  
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;
  
        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });
  
      const sortedProcessedData = processedData.sort((a, b) => 
        a.itemTime.diff(b.itemTime)
      );
  
      const availableResults = sortedProcessedData.filter((item) => item.isAvailable);
      const upcomingRecords = sortedProcessedData.filter((item) => !item.isAvailable);
  
      let recordsToDisplay = [];
  
      if (availableResults.length > 0) {
        recordsToDisplay = [...availableResults];
  
        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }
  
      if (recordsToDisplay.length > 3) {
        recordsToDisplay = recordsToDisplay.slice(-3).reverse();
      }
  
      // Move "wait" items to the top
      recordsToDisplay.sort((a, b) => (a.result === "wait" ? -1 : 1));
  
      setDataFor(recordsToDisplay);
    }
  }, [data, currentTime]);

  return (
    <div className="App">
      {/* seo setup start */}
      <Helmet>
        <title></title>
        <meta name="description" content="satta-king-black,satta king black,sattakingblack" />
        <meta
          name="Keywords"
          content="satta-king-black,satta king black,sattakingblack, sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king,"
        />
        <link rel="canonical" href="https://satta-king-black.co.in" />
      </Helmet>
      {/* seo setup end */}
      <div className="col-12 homeHeader">
        <div className="row main-row">
          <div className=" col-sm-4 col-md-4 text-center text-danger fw-b link">
            <a href="/" name="home">
              🏠HOME
            </a>
          </div>
          <div className="col-sm-4 col-md-4 text-center text-danger fw-b link">
            <a href="http://sattasport.co/" name="satta">
              👑SATTA KING👑
            </a>
          </div>
          <div className="col-sm-4 col-md-4 text-center text-danger fw-b link ">
            <a onClick={pageScroll} name="result">🎯SATTA RESULT🎯</a>
          </div>
        </div>
      </div>
      <marquee className=" marquee-1">
        {" "}
        Satta number, black satta king sss, satta com, gali satta result, satta
        king live result, gali result, satta king live, satta king disawar,
        black satta 786, satta king gali, satta king com, gali satta chart,
        disawar result, satta disawar, satta live result, disawar chart, gali
        chart, satta king bazar, satta king desawar,{" "}
      </marquee>
      <div className="col-12 text-center header-heading">
        <h6>👑SATTA KING | SATTA KING 786 | DELHI SATTA KING👑</h6>
        <h1>SATTA KING BLACK</h1>
      </div>
      <marquee className=" marquee-2">
        "Satta King" is a term used to describe betting on numbers, usually in
        the context of illegal lottery or gambling activities in India. The
        origin of the term "Satta King" is believed to come from the Hindi
        language, where "satta" means betting or gambling, and "king" refers to
        the person who has won or is believed to be the best in the betting
        activity. The practice of satta has been illegal in India for many years
        and is widely considered to be a form of organized crime. Participating
        in satta can be risky and lead to financial losses, and is also often
        associated with other criminal activities such as money laundering and
        extortion.
      </marquee>

      <div className="col-12 text-white text-center p-1">
        <em className="">
          🙋‍♂️INDIA NO.1 SATTA KING SITE SATTASPORT HEARTILY WELCOME. HERE YOU MAY
          GET EXCELLENT ESTIMATION BY PRIME INDIVIDUAL AND QUICK MATKA RESULT.
          GALI DISAWAR FIX SINGLE JODI KE LIYE HERE YOU FIND TOP SATTA MARKET OF
          INDIA FARIDABAD GHAZIABAD GALI DESAWAR SATTA TIPS FAST SATTA RESULT
          GALI DISAWAR GHAZIABAD FARIDABAD SATTA GUESSING POWERED BY
          SATTASPORT.CO🚨🚨
        </em>
      </div>

      {/* Display current date and running time */}
      <div
  className="text-center current-date-time mt-3"
  style={{ color: "#ffffe0", fontSize: "1.5rem", fontWeight: "bold" }}
>
  <h3 style={{ fontSize: "2rem" }}>{currentDate}</h3>
  <h4 style={{ fontSize: "1.8rem" }}>🕗Current Time: {currentTime}</h4> {/* This will update every second */}
</div>


      <div className="text-center currentBanner mt-3">
        {datagame?.map((todayData, index) => (
          <div key={index} className="game">
            <h1 className="mb-0">{todayData?.gameName}</h1>
            <p className="  col-md-12">
              <div className=" blinking-text">{todayData?.result || ""} </div>
            </p>
          </div>
        ))}
      </div>
      <AdvertisementComponent type="odd" />
      <DaySattaResult dayGameData={data} />
      {isDisc && <Disclaimer style={{ display: "none" }} />}
    </div>
  );
}

export default App;
